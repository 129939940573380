import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { Params, useParams } from 'react-router-dom';
import { 
  AddFileTheme, 
  ButtonTheme, 
  EditItemRow, 
  FileSettingsBox, 
  FileSettingsBoxesTheme,
  IconName,
  Loader, 
  useRedirect, 
  UseRedirect, 
} from '@chic-loyalty/ui';
import { 
  FileTypeExtended, 
  RoutingPath,
  SetupType, 
  UploadAssetType, 
} from '@chic/enums';
import { AssetDetails, ScreenObject, UseAnimations } from '@chic/models';
import { BaseViewForAnimations } from '@chic/components';
import { Container } from './adEdit.styled';
import { useAnimations } from '@chic/hooks';
import { stringify } from 'query-string';

export const AdEditView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { type, id }: Params = useParams();
  const { redirect }: UseRedirect = useRedirect();
  const { 
    onAddFile, 
    onSaveFile, 
    onSetAnimationName, 
    animationName, 
    requirements, 
    savedFiles, 
    screens,
    targetOptions,
    acceptedFilesTypes,
    onSavedFilesChangePositions,
    onDeleteSavedFile,
    onEditSetup,
    resetUploadedData,
    onEditFile,
    fileToEdit,
    onAddNewItem,
    buttonBanners,
    onChangeSelectedTargets,
    selectedTargets,
    onDeleteScreen,
    transformAssetDetailsToAddFileInitial,
    setupType,
    uploadErrorMessage,
  }: UseAnimations = useAnimations(
    type as SetupType, 
    type === SetupType.Tv ? UploadAssetType.TVAnimation : UploadAssetType.TabletAnimation,
    undefined,
    id,
  );

  return (
    <Container>
      {!setupType ? <Loader /> : (
        <BaseViewForAnimations 
          title={t(`chic.management.adEditView.${setupType}.title`)} 
          goBack={(): void => redirect(`${RoutingPath.AdsList}?${stringify({ type: setupType }, { skipEmptyString: true })}`)} 
          animationName={animationName}
          onNameChange={onSetAnimationName} 
          addFileProps={{ 
            title: t('chic.management.global.addFile'),
            addFile: onAddFile,
            saveFile: onSaveFile,
            theme: type as AddFileTheme,
            requirements,
            acceptedFilesTypes,
            maxImageSizeInBytes: 2 * 1024 * 1024,
            maxVideoSizeInBytes: 15 * 1024 * 1024,
            withDurationChange: setupType === SetupType.Tv
              ? 'always'
              : 'conditionally',
            resetData: resetUploadedData,
            initialData: transformAssetDetailsToAddFileInitial(fileToEdit),
            errorMessage: uploadErrorMessage,
          }} 
          fileSettingsBoxesProps={{
            title: t('chic.management.adEditView.fileSettingsBoxesProps.title'),
            description: t('chic.management.adEditView.fileSettingsBoxesProps.description'),
            items: savedFiles.map((savedFile: AssetDetails): FileSettingsBox => ({
              name: savedFile.name,
              path: savedFile.path,
              isVideo: savedFile.type === FileTypeExtended.Video,
            })),
            onAddNewItem,
            onItemDelete: onDeleteSavedFile,
            onItemEdit: onEditFile,
            onPositionChange: onSavedFilesChangePositions,
            theme: setupType === SetupType.Tv ? FileSettingsBoxesTheme.Horizontal : FileSettingsBoxesTheme.Vertical,
            addNewButtonLabel: t('chic.management.adEditView.fileSettingsBoxesProps.addNewButtonLabel'),
          }} 
          nameInputLabel={t('chic.management.adEditView.nameInputLabel')} 
          buttonBanners={buttonBanners}
          itemPickerSettings={{
            title: t('chic.management.adEditView.buttonBanner.itemPickerSettings.title'),
            options: targetOptions,
            onDelete: onChangeSelectedTargets,
            onSelect: onChangeSelectedTargets,
            initialTargets: selectedTargets,
          }}
          screensRows={screens.map((screen: ScreenObject): EditItemRow => ({
            icon: IconName.Ads,
            details: {
              label: t('chic.management.adEditView.buttonBanner.screensRows.label'),
              value: screen.name,
            },
            buttons: [
              {
                label: t('chic.management.adEditView.buttonBanner.screensRows.edit'),
                onClick: (): void => redirect(RoutingPath.AdEditScreen, { type: setupType, screenType: screen.type, id: screen.id }),
                buttonTheme: ButtonTheme.ICTextWhite,
              },
              {
                label: t('chic.management.adEditView.buttonBanner.screensRows.delete'),
                onClick: (): void => onDeleteScreen(screen.id),
                buttonTheme: ButtonTheme.ICTextBlue,
              },
            ],
          }))}
          acceptButtonSettings={{
            label: t('chic.management.global.save'),
            action: onEditSetup,
            disabled: !animationName || !savedFiles.length,
          }} 
          cancelButtonSettings={{
            label: t('chic.management.global.cancel'),
            action: (): void => redirect(`${RoutingPath.AdsList}?${stringify({ type: setupType }, { skipEmptyString: true })}`),
          }}
          withBackgroundImage
        />
      )}
    </Container>
  );
};
