import { Color, StyledComponent, TransactionProductBox } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const Section: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TransactionProductBoxesWrapper: StyledComponent<'div'> = styled.div`
  border-top: 1px solid ${Color.ICGray500};
  border-bottom: 1px solid ${Color.ICGray500};
`;

export const StyledTransactionProductBox: StyledComponent<typeof TransactionProductBox> = styled(TransactionProductBox)`
  padding: 24px 0;

  & + & {
    border-top: 1px solid ${Color.ICGray500};
  }
`;
