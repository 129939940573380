import { SubscriptionDetailsContext } from '@chic/contexts';
import { SubscriptionDetailsAction } from '@chic/enums';
import { SubscriptionDetailsContextType, SubscriptionDetailsState, UseSubscriptionDetailsContext } from '@chic/models';
import { useContext } from 'react';

export const useSubscriptionDetailsContext: () => UseSubscriptionDetailsContext = (): UseSubscriptionDetailsContext => {
  const [contextData, dispatch]: SubscriptionDetailsContextType = useContext(SubscriptionDetailsContext);

  const updateSubscriptionDetailsData: (data: SubscriptionDetailsState) => void = (data: SubscriptionDetailsState): void => {
    dispatch({ type: SubscriptionDetailsAction.UpdateSubscriptionDetailsData, payload: data });
  };

  return { subscriptionDetails: contextData.subscriptionDetails, updateSubscriptionDetailsData };
};
