import React, { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { SubscriptionDetailsContext } from '@chic/contexts';
import { 
  SubscriptionDetailsState, 
  SubscriptionDetailsActions, 
  SubscriptionDetailsContextType, 
  FrontendApiError, 
  SubscriptionDetails,
} from '@chic/models';
import { subscriptionDetailsReducer } from '@chic/reducers';
import { QueryKey, RoutingPath, SubscriptionDetailsAction } from '@chic/enums';
import { NavigateFunction, useNavigate, useLocation, redirect, Params, useParams, Location, Outlet } from 'react-router-dom';
import { useNotifications, UseNotifications } from '@chic-loyalty/ui';
import { getSubscriptionDetails } from '@chic/api';
import { emptyRequest } from '@chic/utils';
import { useQuery } from 'react-query';

export const SubscriptionDetailsProvider: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { subscriptionId: id }: Params = useParams();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  
  const subscriptionId: number | null = useMemo(
    (): number | null => {
      const parsedId: number = parseInt(id ?? '', 10);

      return isNaN(parsedId)
        ? null
        : parsedId;
    },
    [id],
  );
  
  useEffect(
    (): void => {
      if (subscriptionId === null) {
        if (location.key !== 'default') {
          navigate(-1);
        } else {
          redirect(RoutingPath.SubscriptionList);
        }
      }
    },
    [subscriptionId],
  );

  const [subscriptionDetailsState, subscriptionDetailsDispatch]: [SubscriptionDetailsState, Dispatch<SubscriptionDetailsActions>] 
  = useReducer(subscriptionDetailsReducer, { subscriptionDetails: null });

  useQuery(
    [QueryKey.SubscriptionDetails],
    (): Promise<SubscriptionDetails | void> => subscriptionId
      ? getSubscriptionDetails(subscriptionId)
      : emptyRequest(),
    {
      onSuccess: (data: SubscriptionDetails | void): void => {
        if (!data) {
          return;
        }
        subscriptionDetailsDispatch(
          { 
            type: SubscriptionDetailsAction.UpdateSubscriptionDetailsData, 
            payload: { subscriptionDetails: data }, 
          },
        );
      },
      onError: (error: FrontendApiError): void => {
        showFullscreenAlert({
          title: t('chic.crmApp.global.error'),
          description: error.message ?? t('chic.crmApp.global.errorMessage'),
          acceptButtonSettings: {
            label: t('chic.management.useSubscriptionDetails.goBackToList'),
            action: (): void => {
              redirect(RoutingPath.SubscriptionList);
              hideFullscreenAlert();
            },
          },
        });
      },
    },
  );

  const valueForSubscriptionDetailsContext: SubscriptionDetailsContextType = useMemo(
    (): SubscriptionDetailsContextType => [
      { subscriptionDetails: subscriptionDetailsState.subscriptionDetails }, subscriptionDetailsDispatch],
    [subscriptionDetailsState],
  );

  return (
    <SubscriptionDetailsContext.Provider value={valueForSubscriptionDetailsContext}>
      <Outlet />
    </SubscriptionDetailsContext.Provider>
  );
};
