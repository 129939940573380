import {
  UseState, 
  DetailRow,
  Color,
  UseParsers,
  useParsers,
  ProgressBarTab,
  UseRedirect,
  useRedirect,
  ChoiceBoxBannerFilledData,
  IconName,
  ButtonTheme,
} from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { RoutingPath, SubscriptionOrderStatus } from '@chic/enums';
import { 
  SubscriptionOrder, 
  UseStatics,
  StaticDefinition,
  UseSubscriptionsUtils,
  UseSubscriptionDetailsContext,
  SubscriptionPayment,
  SubscriptionDelivery, 
} from '@chic/models';
import { useEffect, useMemo, useState } from 'react';
import { useStatics, useSubscriptionDetailsContext, useSubscriptionsUtils } from '@chic/hooks';
import { UseSubscriptionDetailsActions, UseSubscriptionDetailsActionsPermissions, UseSubscriptionDetailsData } from '../types';
import { useSubscriptionDetailsActionsPermissions } from './useSubscriptionDetailsActionsPermissions.hook';
import { useSubscriptionDetailsActions } from './useSubscriptionDetailsActions.hook';
import { deliveryPickupPointTypes } from '@chic/constans';

export const useSubscriptionDetailsData: () => UseSubscriptionDetailsData = (): UseSubscriptionDetailsData => {
  const { t }: TransProps<never> = useTranslation();
  const { parsePrice }: UseParsers = useParsers();
  const { redirect }: UseRedirect = useRedirect();
  const { subscriptionDetails }: UseSubscriptionDetailsContext = useSubscriptionDetailsContext();
  const { 
    getDeliveryNameByType, 
    getPaymentNameByType,
    orderStatuses,
    getPaymentLogoByType,
  }: UseStatics = useStatics();
  const { actionsPermissions }: UseSubscriptionDetailsActionsPermissions = useSubscriptionDetailsActionsPermissions();
  const { transformLocationToDeliveryAddress }: UseSubscriptionsUtils = useSubscriptionsUtils();
  const { manualAcceptPayment }: UseSubscriptionDetailsActions = useSubscriptionDetailsActions();
  const [currentOrder, setCurrentOrder]: UseState<SubscriptionOrder | undefined> = useState<SubscriptionOrder | undefined>(undefined);
  const [subscriptionOrders, setSubscriptionOrders]: UseState<SubscriptionOrder[]> = useState<SubscriptionOrder[]>([]);
  const currency: string = 'zł';

  useEffect(
    (): void => {
      if (subscriptionDetails) {
        if (subscriptionDetails.orders[0]?.status !== SubscriptionOrderStatus.Complete) {
          setCurrentOrder(subscriptionDetails.orders[0]);
          setSubscriptionOrders(subscriptionDetails.orders.slice(1));
        } else {
          setCurrentOrder(undefined);
          setSubscriptionOrders(subscriptionDetails.orders);
        }
      }
    },
    [subscriptionDetails],
  );

  const detailsRowsData: DetailRow[] = useMemo(
    (): DetailRow[] => {
      if (!subscriptionDetails) {
        return [];
      }

      return [
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.email'),
          value: subscriptionDetails.customer.email ?? '',
          color: Color.ICYellow100,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.phone'),
          value: subscriptionDetails.customer.phone ?? '',
          color: Color.ICYellow100,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.planName'),
          value: subscriptionDetails.contract.planName,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.regularPrice'),
          value: `${parsePrice(subscriptionDetails.contract.regularPrice)} ${currency}`,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.discountPercentage'),
          value: `${subscriptionDetails.contract.discountPercentage}%`,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.deliveryCost'),
          value: `${parsePrice(subscriptionDetails.contract.deliveryCost)} ${currency}`,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.discountedPrice'),
          value: `${parsePrice(subscriptionDetails.contract.discountedPrice + subscriptionDetails.contract.deliveryCost)} ${currency}`,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.initDatetime'),
          value: subscriptionDetails.contract.initDatetime,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.storeName'),
          value: subscriptionDetails.contract.initLocation?.store 
            ? `${subscriptionDetails.contract.initLocation.store.name} ${subscriptionDetails.contract.initLocation.store.address}`
            : t('chic.management.global.noData'),
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.employeeName'),
          value: subscriptionDetails.contract.initEmployee.employeeName ?? t('chic.management.global.noData'),
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.deliveryAddress'),
          value: transformLocationToDeliveryAddress(subscriptionDetails.contract.delivery),
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.deliveryType'),
          value: subscriptionDetails.contract.delivery.type
            ? getDeliveryNameByType(subscriptionDetails.contract.delivery.type)
            : t('chic.management.global.noData'),
          textButton: actionsPermissions.changeDeliveryDestination ? {
            label: t('chic.management.global.change'),
            onClick: (): void => redirect(RoutingPath.SubscriptionEditDelivery, { subscriptionId: subscriptionDetails.id }),
          } : undefined,
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.paymentType'),
          value: getPaymentNameByType(subscriptionDetails.contract.paymentType),
        },
        {
          label: t('chic.management.useSubscriptionDetails.detailsRowsData.paymentUrl'),
          value: currentOrder?.payments[currentOrder.payments.length - 1]?.url ?? (currentOrder?.status &&
            [SubscriptionOrderStatus.OnDelivery, SubscriptionOrderStatus.Complete].includes(currentOrder.status) 
            ? t('chic.management.useSubscriptionDetails.detailsRowsData.orderPaid')
            : t('chic.management.global.noData')
          ),
          color: Color.ICBlue,
          isCopyable: !!currentOrder?.payments[currentOrder.payments.length - 1]?.url,
        },
      ];
    },
    [subscriptionDetails, getDeliveryNameByType],
  );

  const orderProgressBarStatuses: ProgressBarTab[] = useMemo(
    (): ProgressBarTab[] => {
      if (!currentOrder) {
        return [];
      }

      return orderStatuses
        // TODO: fix when be will be ready
        .filter((status: StaticDefinition<SubscriptionOrderStatus>): boolean => [
          SubscriptionOrderStatus.Complete, 
          SubscriptionOrderStatus.OnDelivery, 
          SubscriptionOrderStatus.WaitingForPayment,
        ].includes(status.code))
        .map((status: StaticDefinition<SubscriptionOrderStatus>): ProgressBarTab => ({
          label: status.name,
          name: status.code,
        }));
    },
    [currentOrder, orderStatuses],
  );

  const currentOrderDelivery: ChoiceBoxBannerFilledData | undefined = useMemo(
    (): ChoiceBoxBannerFilledData | undefined => {
      if (!currentOrder?.deliveries[currentOrder.deliveries.length - 1]) {
        return;
      }

      const delivery: SubscriptionDelivery = currentOrder.deliveries[currentOrder.deliveries.length - 1];

      return {
        title: `${getDeliveryNameByType(delivery.type)} ${delivery.location.pickupPoint?.externalId}`,
        description: `${delivery.location.address}, ${delivery.location.postalCode} ${delivery.location.city}`,
        buttonSettings: {
          action: (): void => delivery?.trackingUrl ? redirect(delivery.trackingUrl, undefined, true) : undefined,
          label: t('chic.management.useSubscriptionDetails.currentOrderDelivery.buttonLabel'),
          buttonTheme: ButtonTheme.ICText,
          disabled: !delivery?.trackingUrl,
        },
        icon: deliveryPickupPointTypes.includes(delivery.type) ? IconName.ParcelLocker : IconName.DeliveryCourier,
        badge: delivery.cost === 0 ? { label:  t('chic.management.useSubscriptionDetails.currentOrderDelivery.freeDelivery') } : undefined,
      };
    },
    [currentOrder?.deliveries],
  );

  const currentOrderPayment: ChoiceBoxBannerFilledData | undefined = useMemo(
    (): ChoiceBoxBannerFilledData | undefined => {
      if (!currentOrder?.payments[currentOrder.payments.length - 1] || !subscriptionDetails?.id) {
        return;
      }

      const payment: SubscriptionPayment = currentOrder.payments[currentOrder.payments.length - 1];

      return {
        title: getPaymentNameByType(payment.type),
        titleDetails: String(payment.id),
        description: payment.url ? t('chic.management.useSubscriptionDetails.currentOrderPayment.paymentUrl') : undefined,
        linkToCopy: payment.url ?? undefined,
        buttonSettings: {
          action: (): void => {
            manualAcceptPayment(subscriptionDetails.id, payment.id);
          },
          label: t('chic.management.useSubscriptionDetails.currentOrderPayment.buttonLabel'),
          buttonTheme: ButtonTheme.ICText,
          disabled: !actionsPermissions.manualAcceptPayment,
        },
        icon: IconName.PaymentWeb,
        badge: { logo: getPaymentLogoByType(payment.type) },
      };
    },
    [currentOrder?.payments],
  );

  return { 
    subscriptionDetails,
    subscriptionOrders,
    currentOrder,
    detailsRowsData,
    orderProgressBarStatuses,
    actionsPermissions,
    currentOrderDelivery,
    currentOrderPayment,
  };
};
