export enum FileFromViews {
  // global
  Background = '/assets/background.jpg',
  MobileBackground = '/assets/mobileBackground.jpg',
  // payments logos
  PaymentLogoCard = '/assets/logos/payments/card.svg',
  PaymentLogoOnline = '/assets/logos/payments/online.svg',
  PaymentLogoPayu = '/assets/logos/payments/payu.svg',
  PaymentLogoPos = '/assets/logos/payments/pos.svg',
  PaymentLogoPrzelewy24 = '/assets/logos/payments/przelewy24.svg',
  // subscriptionsList view
  SubscriptionsListViewBackground = '/assets/subscriptionsList/background.png',
  SubscriptionsListViewBackgroundMobile = '/assets/subscriptionsList/backgroundMobile.png',
  SubscriptionsListResultInfoBackground = '/assets/subscriptionsList/resultInfoBackground.png',
  // discountCodesSearch view
  DiscountCodesSearchMagnifier = '/assets/views/discountCodesSearch/magnifier.svg',
  DiscountCodesSearchMagnifier1 = '/assets/views/discountCodesSearch/magnifier1.svg',
  DiscountCodeSearchClose = '/assets/views/discountCodesSearch/close.svg',
  DiscountCodeSearchDevices = '/assets/views/discountCodesSearch/devices.png',
  // adsList view
  AdsListViewBackground = '/assets/views/adsList/background.png',
  // useAnimations
  UseAnimationsBannerCategories = '/assets/hooks/useAnimations/bannerCategories.png',
  UseAnimationsBannerSlider = '/assets/hooks/useAnimations/bannerSlider.png',
  // baseViewForAnimations
  BaseViewForAnimationsBackground = '/assets/components/baseViewForAnimations/background.png',
  // baseViewForSubscriptionDetails
  BaseViewForSubscriptionDetailsBackground = '/assets/components/baseViewForSubscriptionDetails/background.png',
  BaseViewForSubscriptionDetailsBasicDataBackground = '/assets/components/baseViewForSubscriptionDetails/backgroundBasicData.png',
  // refundsList view
  RefundsListViewBackground = '/assets/views/refundsList/background.png',
  RefundsListViewBackgroundMobile = '/assets/views/refundsList/backgroundMobile.jpg',
  RefundsListViewValueBoxBackground = '/assets/views/refundsList/valueBoxBackground.jpg',
  // subscriptionEditDeliveryPoint view
  SubscriptionEditDeliveryPointEmptyStateBackground = '/assets/views/subscriptionEditDeliveryPoint/emptyStateBackground.png',
  SubscriptionEditDeliveryPointEmptyStateBackgroundMobile = '/assets/views/subscriptionEditDeliveryPoint/emptyStateBackgroundMobile.png',
  // subscriptionOrder view
  SubscriptionOrderViewBackground = '/assets/views/subscriptionOrder/background.png',
  SubscriptionOrderViewBackgroundMobile = '/assets/views/subscriptionOrder/backgroundMobile.png',
  // subscriptionDetailsCurrentOrder view
  SubscriptionDetailsCurrentOrderViewEmptyState = '/assets/views/subscriptionDetailsCurrentOrder/emptyState.png',
  SubscriptionDetailsCurrentOrderViewTrackingEditBanner = '/assets/views/subscriptionDetailsCurrentOrder/trackingEditBanner.jpg',
  SubscriptionDetailsCurrentOrderViewTrackingEditBannerMobile 
  = '/assets/views/subscriptionDetailsCurrentOrder/trackingEditBannerMobile.jpg',
  // subscriptionDetailsAmendmentsHistory view
  SubscriptionDetailsAmendmentsHistoryViewEmptyState = '/assets/views/subscriptionDetailsAmendmentsHistory/emptyState.png',
  // subscriptionDetailsOrdersHistory view
  SubscriptionDetailsOrdersHistoryViewDevices = '/assets/views/subscriptionDetailsOrdersHistory/devices.png',
  SubscriptionDetailsOrdersHistoryViewPaymentsTableBackground 
  = '/assets/views/subscriptionDetailsOrdersHistory/paymentsTableBackground.jpg',
  SubscriptionDetailsOrdersHistoryViewDeliveriesTableBackground 
  = '/assets/views/subscriptionDetailsOrdersHistory/deliveriesTableBackground.jpg',
  SubscriptionDetailsOrdersHistoryViewEmptyState = '/assets/views/subscriptionDetailsOrdersHistory/emptyState.png',
}
