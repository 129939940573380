import { SubscriptionOrder, UseAuth, UseSubscriptionDetailsContext } from '@chic/models';
import { 
  PermissionScopeName, 
  SubscriptionOrderStatus, 
  SubscriptionPaymentStatus, 
  SubscriptionPaymentType, 
  SubscriptionPermissionId, 
  SubscriptionStatus, 
} from '@chic/enums';
import { useMemo } from 'react';
import { useAuth, useSubscriptionDetailsContext } from '@chic/hooks';
import { Permission } from '@chic/interfaces';
import { UseSubscriptionDetailsActionsPermissions, ActionTypes } from '../types';

export const useSubscriptionDetailsActionsPermissions: () => UseSubscriptionDetailsActionsPermissions 
  = (): UseSubscriptionDetailsActionsPermissions => {
    const { authData: { user } }: UseAuth = useAuth();
    const { subscriptionDetails }: UseSubscriptionDetailsContext = useSubscriptionDetailsContext();
  
    const isActionPermitted: (
      scope: PermissionScopeName, permissionId?: SubscriptionPermissionId
    ) => boolean = (
      scope: PermissionScopeName, permissionId?: SubscriptionPermissionId,
    ): boolean => {
      if (subscriptionDetails?.contract.status !== SubscriptionStatus.Active) {
        return false;
      }

      let subscriptionPermission: Permission<SubscriptionPermissionId> | undefined;
      if (permissionId) {
        subscriptionPermission = subscriptionDetails?.permissions
          .find((item: Permission<SubscriptionPermissionId>): boolean => item.id === permissionId);
      }

      return !!user?.scopes.includes(scope) && (!subscriptionPermission || !!subscriptionPermission.value);
    };

    const actionsPermissions: Record<ActionTypes, boolean> = useMemo(
      (): Record<ActionTypes, boolean> => {
        const currentOrder: SubscriptionOrder | undefined = subscriptionDetails?.orders[0];

        return {
          'cancelOrder': isActionPermitted(PermissionScopeName.BackofficeSubscriptionsOrderCancel, SubscriptionPermissionId.CanCancelOrder),
          'createOrder': isActionPermitted(PermissionScopeName.BackofficeSubscriptionsOrderCreate, SubscriptionPermissionId.CanCreateOrder),
          'manualConfirmDelivery': (
            isActionPermitted(PermissionScopeName.BackofficeSubscriptionsDeliveryConfirm)
          // TODO: move to SubscriptionPermissionId (backend)
          && !!currentOrder?.deliveries[currentOrder?.deliveries?.length - 1]
          ),
          'cancelWholeSubscription': isActionPermitted(PermissionScopeName.BackofficeSubscriptionsCancel),
          'manualAcceptPayment': (
            isActionPermitted(PermissionScopeName.BackofficeSubscriptionsPaymentAccept)
          // TODO: move to SubscriptionPermissionId (backend)
          && currentOrder?.payments[currentOrder?.payments?.length - 1]?.status === SubscriptionPaymentStatus.Pending
          ),
          'manualCreatePayment': (
            isActionPermitted(PermissionScopeName.BackofficeSubscriptionsPaymentCreate)
            // TODO: move to SubscriptionPermissionId (backend)
            && currentOrder?.status === SubscriptionOrderStatus.WaitingForPayment
            && currentOrder?.payments[currentOrder?.payments?.length - 1]?.status !== SubscriptionPaymentStatus.Success
            && [
              SubscriptionPaymentType.Payu,
              SubscriptionPaymentType.PayuPending,
              SubscriptionPaymentType.Przelewy24,
              SubscriptionPaymentType.Przelewy24Pending,
            ].includes(currentOrder?.payments[currentOrder?.payments?.length - 1]?.type)
          ),
          'changeDeliveryDestination': isActionPermitted(
            PermissionScopeName.BackofficeSubscriptionsDeliveryUpdate, 
            SubscriptionPermissionId.CanChangeDeliveryDestination,
          ),
        };
      },
      [user?.scopes, subscriptionDetails],
    );

    return { actionsPermissions };
  };
