import { UseNotifications, useNotifications, useRedirect, UseRedirect } from '@chic-loyalty/ui';
import {
  cancelSubscription,
  cancelUnpaidSubscriptionOrder,
  createNewPaymentForSubscriptionOrder,
  manualAcceptPaymentForSubscription,
  manualConfirmSubscriptionDelivery,
  updateSubscriptionDelivery,
} from '@chic/api';
import { TransProps, useTranslation } from 'react-i18next';
import { FrontendApiError, SubscriptionDetails, UseSubscriptionDetailsContext } from '@chic/models';
import { UseSubscriptionDetailsActions } from '../types';
import { useSubscriptionDetailsContext } from '@chic/hooks';
import { RoutingPath, SessionStorage, SubscriptionPaymentType } from '@chic/enums';
import { UpdateSubscriptionDeliveryRequest } from '@chic/interfaces';

export const useSubscriptionDetailsActions: () => UseSubscriptionDetailsActions = (): UseSubscriptionDetailsActions => {
  const { showFullscreenAlert, hideFullscreenAlert, addToast }: UseNotifications = useNotifications();
  const { updateSubscriptionDetailsData }: UseSubscriptionDetailsContext = useSubscriptionDetailsContext();
  const { redirect }: UseRedirect = useRedirect();
  const { t }: TransProps<never> = useTranslation();

  const cancelOrder: (subscriptionId: number, orderId: number) => void = (subscriptionId: number, orderId: number): void => {
    showFullscreenAlert({
      title: t('chic.management.subscriptionDetailsView.actions.cancelOrder.confirmTitle'),
      acceptButtonSettings: {
        label: t('chic.management.global.yes'),
        action: (): void => {
          cancelUnpaidSubscriptionOrder(subscriptionId, orderId)
            .then((subscriptionDetails: SubscriptionDetails): void => {
              addToast({ content: t('chic.management.subscriptionDetailsView.actions.cancelOrder.successToast') });
              updateSubscriptionDetailsData({ subscriptionDetails });
            })
            .catch((error: FrontendApiError): void =>
              addToast({ content: t(error.message ?? 'chic.management.subscriptionDetailsView.actions.cancelOrder.error') }),
            );
          hideFullscreenAlert();
        },
      },
      cancelButtonSettings: {
        label: t('chic.management.global.no'),
        action: hideFullscreenAlert,
      },
    });
  };

  const manualConfirmDelivery: (subscriptionId: number, deliveryId: number) => void = (
    subscriptionId: number, deliveryId: number,
  ): void => {
    showFullscreenAlert({
      title: t('chic.management.subscriptionDetailsView.actions.manualConfirmDelivery.confirmTitle'),
      acceptButtonSettings: {
        label: t('chic.management.global.yes'),
        action: (): void => {
          manualConfirmSubscriptionDelivery(subscriptionId, deliveryId)
            .then((subscriptionDetails: SubscriptionDetails): void => {
              addToast({ content: t('chic.management.subscriptionDetailsView.actions.manualConfirmDelivery.successToast') });
              updateSubscriptionDetailsData({ subscriptionDetails });
            })
            .catch((error: FrontendApiError): void => {
              addToast({
                content: t(
                  error.message ?? 'chic.management.subscriptionDetailsView.actions.manualConfirmDelivery.error',
                ),
              });
            });
          hideFullscreenAlert();
        },
      },
      cancelButtonSettings: {
        label: t('chic.management.global.no'),
        action: hideFullscreenAlert,
      },
    });
  };

  const cancelWholeSubscription: (subscriptionId: number) => void = (subscriptionId: number): void => {
    showFullscreenAlert({
      title: t('chic.management.subscriptionDetailsView.actions.cancelSubscription.confirmTitle'),
      acceptButtonSettings: {
        label: t('chic.management.global.yes'),
        action: (): void => {
          cancelSubscription(subscriptionId)
            .then((subscriptionDetails: SubscriptionDetails): void => {
              addToast({ content: t('chic.management.subscriptionDetailsView.actions.cancelSubscription.successToast') });
              updateSubscriptionDetailsData({ subscriptionDetails });
            })
            .catch((error: FrontendApiError): void =>
              addToast({ content: t(error.message ?? 'chic.management.subscriptionDetailsView.actions.cancelSubscription.error') }),
            );
          hideFullscreenAlert();
        },
      },
      cancelButtonSettings: {
        label: t('chic.management.global.no'),
        action: hideFullscreenAlert,
      },
    });
  };

  const manualAcceptPayment: (subscriptionId: number, paymentId: number) => void = (subscriptionId: number, paymentId: number): void => {
    showFullscreenAlert({
      title: t('chic.management.subscriptionDetailsView.actions.manualAcceptPayment.confirmTitle'),
      acceptButtonSettings: {
        label: t('chic.management.global.yes'),
        action: (): void => {
          manualAcceptPaymentForSubscription(subscriptionId, paymentId)
            .then((subscriptionDetails: SubscriptionDetails): void => {
              addToast({ content: t('chic.management.subscriptionDetailsView.actions.manualAcceptPayment.successToast') });
              updateSubscriptionDetailsData({ subscriptionDetails });
            })
            .catch((error: FrontendApiError): void =>
              addToast({ content: t(error.message ?? 'chic.management.subscriptionDetailsView.actions.manualAcceptPayment.error') }),
            );
          hideFullscreenAlert();
        },
      },
      cancelButtonSettings: {
        label: t('chic.management.global.no'),
        action: hideFullscreenAlert,
      },
    });
  };

  const manualCreatePayment: (subscriptionId: number, orderId: number, paymentMethod: SubscriptionPaymentType) => void = (
    subscriptionId: number, orderId: number, paymentMethod: SubscriptionPaymentType,
  ): void => {
    const paymentType: SubscriptionPaymentType | null = [
      SubscriptionPaymentType.Payu, 
      SubscriptionPaymentType.Przelewy24,
    ].includes(paymentMethod) 
      ? paymentMethod 
      : SubscriptionPaymentType.Payu;

    showFullscreenAlert({
      title: t('chic.management.subscriptionDetailsView.actions.manualCreatePayment.confirmTitle'),
      acceptButtonSettings: {
        label: t('chic.management.global.yes'),
        action: (): void => {
          createNewPaymentForSubscriptionOrder(subscriptionId, orderId, paymentType)
            .then((subscriptionDetails: SubscriptionDetails): void => {
              addToast({ content: t('chic.management.subscriptionDetailsView.actions.manualCreatePayment.successToast') });
              updateSubscriptionDetailsData({ subscriptionDetails });
            })
            .catch((error: FrontendApiError): void =>
              addToast({ content: t(error.message ?? 'chic.management.subscriptionDetailsView.actions.manualCreatePayment.error') }),
            );
          hideFullscreenAlert();
        },
      },
      cancelButtonSettings: {
        label: t('chic.management.global.no'),
        action: hideFullscreenAlert,
      },
    });
  };

  const updateDeliveryDestination: (subscriptionId: number, data: UpdateSubscriptionDeliveryRequest) => void = (
    subscriptionId: number, data: UpdateSubscriptionDeliveryRequest,
  ): void => {
    showFullscreenAlert({
      title: t('chic.management.subscriptionDetailsView.actions.updateDeliveryDestination.confirmTitle'),
      acceptButtonSettings: {
        label: t('chic.management.global.yes'),
        action: (): void => {
          updateSubscriptionDelivery(subscriptionId, data)
            .then((subscriptionDetails: SubscriptionDetails): void => {
              sessionStorage.removeItem(SessionStorage.PickupPointId);
              sessionStorage.removeItem(SessionStorage.SelectedDeliveryType);
              addToast({ content: t('chic.management.subscriptionDetailsView.actions.updateDeliveryDestination.successToast') });
              updateSubscriptionDetailsData({ subscriptionDetails });
              redirect(RoutingPath.SubscriptionDetailsBasicData, { subscriptionId });
            })
            .catch((error: FrontendApiError): void =>
              addToast({ content: t(error.message ?? 'chic.management.subscriptionDetailsView.actions.updateDeliveryDestination.error') }),
            );
          hideFullscreenAlert();
        },
      },
      cancelButtonSettings: {
        label: t('chic.management.global.no'),
        action: hideFullscreenAlert,
      },
    });
  };

  return {
    cancelOrder,
    manualConfirmDelivery,
    cancelWholeSubscription,
    manualAcceptPayment,
    manualCreatePayment,
    updateDeliveryDestination,
  };
};
