import { SubscriptionDetailsAction } from '@chic/enums';
import { SubscriptionDetailsActions, SubscriptionDetailsState } from '@chic/models';

export const subscriptionDetailsReducer: (
  state: SubscriptionDetailsState, action: SubscriptionDetailsActions
) => SubscriptionDetailsState = (
  state: SubscriptionDetailsState, action: SubscriptionDetailsActions,
): SubscriptionDetailsState => {
  switch (action.type) {
    case SubscriptionDetailsAction.UpdateSubscriptionDetailsData:
      return { ...state, ...action.payload };
    default: 
      return state;
  }
};
